// src/pages/Inkooporders.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Edit3, Trash2, Send, Download, Filter, FileText } from 'lucide-react';
import { getOrders, deleteOrder, updateOrderStatus } from '../services/orderService';
import { generateOrderPDF, getDownloadUrl } from '../services/pdfService';
import DeleteModal from '../components/DeleteModal';
import EmailModal from '../components/EmailModal';

const Inkooporders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('Alle');
  const [dateFilter, setDateFilter] = useState('Deze Maand');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const data = await getOrders();
      setOrders(data);
      setError('');
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van de orders.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (order) => {
    setSelectedOrder(order);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!selectedOrder) return;
    
    try {
      await deleteOrder(selectedOrder._id);
      setOrders(orders.filter(o => o._id !== selectedOrder._id));
      setShowDeleteModal(false);
      setSelectedOrder(null);
    } catch (err) {
      setError('Er is een fout opgetreden bij het verwijderen van de order.');
      console.error(err);
    }
  };

  const handleGeneratePDF = async (order) => {
    try {
      setIsPdfLoading(true);
      const result = await generateOrderPDF(order._id);
      
      if (result.success) {
        // Open the PDF in a new window
        window.open(getDownloadUrl(result.filename), '_blank');
      }
    } catch (err) {
      console.error('Full PDF Generation Error:', err);
      setError('Er is een fout opgetreden bij het genereren van de PDF.');
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleSendClick = (order) => {
    setSelectedOrder(order);
    setShowEmailModal(true);
  };
  
  const handleEmailSuccess = (result) => {
    setShowEmailModal(false);
    
    // Update the order status if it changed (from concept to sent)
    if (result && result.orderStatus && selectedOrder) {
      const updatedOrders = orders.map(o => {
        if (o._id === selectedOrder._id) {
          return { ...o, status: result.orderStatus };
        }
        return o;
      });
      
      setOrders(updatedOrders);
      
      // Show success message
      setSuccessMessage('Inkooporder is succesvol verzonden per e-mail');
      
      // Clear success message after delay
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nl-NL', options);
  };

  // Calculate order total
  const calculateOrderTotal = (order) => {
    return order.items.reduce((total, item) => {
      return total + (item.quantity * item.price * (1 + item.vat / 100));
    }, 0);
  };

  // Translate status for display
  const getStatusLabel = (status) => {
    const statusMap = {
      'concept': 'Concept',
      'sent': 'Verzonden',
      'paid': 'Betaald',
      'cancelled': 'Geannuleerd'
    };
    return statusMap[status] || status;
  };

  // Get status style
  const getStatusStyle = (status) => {
    switch (status) {
      case 'paid':
        return {
          backgroundColor: 'rgba(16, 185, 129, 0.1)',
          color: 'var(--color-success)'
        };
      case 'sent':
        return {
          backgroundColor: 'rgba(59, 130, 246, 0.1)',
          color: 'var(--color-accent)'
        };
      case 'cancelled':
        return {
          backgroundColor: 'rgba(220, 38, 38, 0.1)',
          color: 'var(--color-error)'
        };
      default:
        return {
          backgroundColor: 'var(--color-bg-tertiary)',
          color: 'var(--color-text-secondary)'
        };
    }
  };

  // Filter orders
  const filteredOrders = orders.filter(order => {
    const matchesSearch = 
      (order.orderNumber && order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase())) || 
      (order.customer && typeof order.customer === 'object' && order.customer.name && 
       order.customer.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (order.project && order.project.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesStatus = statusFilter === 'Alle' || 
      (statusFilter === 'Concept' && order.status === 'concept') ||
      (statusFilter === 'Verzonden' && order.status === 'sent') ||
      (statusFilter === 'Betaald' && order.status === 'paid') ||
      (statusFilter === 'Geannuleerd' && order.status === 'cancelled');
    
    // For simplicity, we're not implementing date filtering yet
    return matchesSearch && matchesStatus;
  });

  return (
    <>
      {/* Header */}
      <header style={{ backgroundColor: 'var(--color-bg-primary)', borderBottom: '1px solid var(--color-border)' }} 
              className="shadow-sm p-4 flex flex-col sm:flex-row justify-between items-center gap-4">
        <h2 style={{ color: 'var(--color-text-primary)' }} className="text-2xl font-semibold">Inkooporders</h2>
        <div className="flex flex-wrap gap-2 w-full sm:w-auto">
          <Link 
            to="/nieuwe-order" 
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center text-white transition-all duration-200"
            style={{ 
              backgroundColor: 'var(--color-accent)',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
              e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
              e.currentTarget.style.transform = 'translateY(-1px)';
              e.currentTarget.style.color = '#ffffff';
              e.currentTarget.style.fontWeight = '600';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-accent)';
              e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.fontWeight = 'normal';
            }}
          >
            <Plus className="mr-2" size={18} /> Nieuwe Order
          </Link>
          <button 
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center transition-all duration-200"
            style={{ 
              backgroundColor: 'var(--color-bg-tertiary)', 
              color: 'var(--color-text-primary)',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-hover-btn)';
              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.transform = 'translateY(-1px)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-bg-tertiary)';
              e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            <Download size={18} className="mr-2" /> Exporteren
          </button>
        </div>
      </header>
      
      {/* Content Area */}
      <main style={{ backgroundColor: 'var(--color-bg-secondary)' }} className="flex-1 overflow-x-hidden overflow-y-auto p-4 sm:p-6">
        {error && (
          <div style={{ 
            backgroundColor: 'rgba(220, 38, 38, 0.1)',
            borderColor: 'var(--color-error)',
            color: 'var(--color-error)'
          }} className="mb-4 px-4 py-3 rounded-md border-l-4">
            {error}
          </div>
        )}
        
        {successMessage && (
          <div 
            style={{ 
              backgroundColor: 'rgba(16, 185, 129, 0.1)',
              borderColor: 'var(--color-success)',
              color: 'var(--color-success)',
              animation: 'slideDown 0.5s ease-out forwards'
            }} 
            className="mb-4 px-4 py-3 rounded-md border-l-4 flex items-center"
          >
            <svg className="h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {successMessage}
          </div>
        )}
        
        {/* Filters */}
        <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg p-4 mb-6">
          <div className="flex flex-col gap-4">
            <div className="w-full">
              <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Zoeken</label>
              <input 
                type="text" 
                placeholder="Zoek op ordernummer, klant, of project..." 
                className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                style={{ 
                  backgroundColor: 'var(--color-input-bg)',
                  borderColor: 'var(--color-input-border)',
                  color: 'var(--color-text-primary)'
                }} 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="flex flex-wrap gap-4">
              <div className="w-full sm:w-48">
                <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Status</label>
                <select 
                  className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  style={{ 
                    backgroundColor: 'var(--color-input-bg)',
                    borderColor: 'var(--color-input-border)',
                    color: 'var(--color-text-primary)'
                  }}
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option>Alle</option>
                  <option>Concept</option>
                  <option>Verzonden</option>
                  <option>Betaald</option>
                  <option>Geannuleerd</option>
                </select>
              </div>
              
              <div className="w-full sm:w-48">
                <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Periode</label>
                <select 
                  className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  style={{ 
                    backgroundColor: 'var(--color-input-bg)',
                    borderColor: 'var(--color-input-border)',
                    color: 'var(--color-text-primary)'
                  }}
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                >
                  <option>Deze Maand</option>
                  <option>Afgelopen Kwartaal</option>
                  <option>Heel Jaar</option>
                </select>
              </div>
              
              <div className="flex items-end w-full sm:w-auto">
                <button 
                  className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center text-white transition-all duration-200"
                  style={{ 
                    backgroundColor: 'var(--color-accent)',
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
                    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
                    e.currentTarget.style.transform = 'translateY(-1px)';
                    e.currentTarget.style.color = '#ffffff';
                    e.currentTarget.style.fontWeight = '600';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                    e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.color = 'white';
                    e.currentTarget.style.fontWeight = 'normal';
                  }}
                >
                  <Filter size={18} className="mr-2" /> Filter Toepassen
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Orders Table */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"></div>
          </div>
        ) : (
          <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg overflow-hidden">
            <div style={{ 
              backgroundColor: 'var(--color-bg-tertiary)',
              borderBottom: '1px solid var(--color-border)'
            }} className="p-4">
              <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">Alle Inkooporders</h3>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead style={{ backgroundColor: 'var(--color-bg-tertiary)', borderBottom: '1px solid var(--color-border)' }}>
                  <tr>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Order Nr</th>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Klant</th>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="hidden md:table-cell p-3 text-left text-xs font-medium uppercase tracking-wider">Project</th>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="hidden md:table-cell p-3 text-left text-xs font-medium uppercase tracking-wider">Datum</th>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Totaal</th>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="hidden sm:table-cell p-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                    <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.length > 0 ? (
                    filteredOrders.map((order) => (
                      <tr key={order._id} 
                          style={{ borderBottom: '1px solid var(--color-border)' }} 
                          className="transition-hover"
                          onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--color-hover-light)'} 
                          onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                        <td style={{ color: 'var(--color-text-primary)' }} className="p-3">{order.orderNumber}</td>
                        <td style={{ color: 'var(--color-text-primary)' }} className="p-3">
                          {order.customer && typeof order.customer === 'object' 
                            ? order.customer.name 
                            : 'Onbekende klant'}
                        </td>
                        <td style={{ color: 'var(--color-text-primary)' }} className="hidden md:table-cell p-3">{order.project}</td>
                        <td style={{ color: 'var(--color-text-primary)' }} className="hidden md:table-cell p-3">{formatDate(order.date)}</td>
                        <td style={{ color: 'var(--color-text-primary)' }} className="p-3 font-semibold">€ {calculateOrderTotal(order).toFixed(2)}</td>
                        <td className="hidden sm:table-cell p-3">
                          <span style={getStatusStyle(order.status)} className="inline-flex px-2 py-1 text-xs font-medium rounded-full">
                            {getStatusLabel(order.status)}
                          </span>
                        </td>
                        <td className="p-3">
                          <div className="flex space-x-1 sm:space-x-2">
                            <Link to={`/inkooporders/${order._id}/edit`} 
                                  className="p-1 rounded-md transition-all duration-200"
                                  style={{ color: 'var(--color-accent)' }}
                                  onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = 'var(--color-hover-primary)';
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = '';
                                    e.currentTarget.style.transform = 'scale(1)';
                                  }}>
                              <Edit3 size={16} className="sm:w-[18px] sm:h-[18px]" />
                            </Link>
                            <button 
                              className="p-1 rounded-md transition-all duration-200"
                              style={{ color: 'var(--color-text-secondary)' }}
                              onClick={() => handleGeneratePDF(order)}
                              disabled={isPdfLoading}
                              onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = 'var(--color-hover-light)';
                                e.currentTarget.style.transform = 'scale(1.1)';
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = '';
                                e.currentTarget.style.transform = 'scale(1)';
                              }}
                            >
                              <FileText size={16} className="sm:w-[18px] sm:h-[18px]" />
                            </button>
                            <button 
                              className="p-1 rounded-md transition-all duration-200"
                              style={{ color: 'var(--color-success)' }}
                              onClick={() => handleSendClick(order)}
                              onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = 'var(--color-hover-success)';
                                e.currentTarget.style.transform = 'scale(1.1)';
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = '';
                                e.currentTarget.style.transform = 'scale(1)';
                              }}>
                              <Send size={16} className="sm:w-[18px] sm:h-[18px]" />
                            </button>
                            <button 
                              className="p-1 rounded-md transition-all duration-200"
                              style={{ color: 'var(--color-error)' }}
                              onClick={() => handleDeleteClick(order)}
                              onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = 'var(--color-hover-error)';
                                e.currentTarget.style.transform = 'scale(1.1)';
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = '';
                                e.currentTarget.style.transform = 'scale(1)';
                              }}
                            >
                              <Trash2 size={16} className="sm:w-[18px] sm:h-[18px]" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ color: 'var(--color-text-secondary)' }} className="p-4 text-center">
                        Geen orders gevonden. Maak uw eerste order aan.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            {/* Pagination (simplified) */}
            {filteredOrders.length > 0 && (
              <div style={{ 
                backgroundColor: 'var(--color-bg-tertiary)', 
                borderTop: '1px solid var(--color-border)'
              }} className="p-4 flex justify-between">
                <div style={{ color: 'var(--color-text-secondary)' }} className="text-sm">
                  Toont 1 - {filteredOrders.length} van {filteredOrders.length} orders
                </div>
                <div className="flex space-x-2">
                  <button disabled style={{ 
                    backgroundColor: 'var(--color-bg-tertiary)', 
                    borderColor: 'var(--color-border)',
                    color: 'var(--color-text-tertiary)'
                  }} className="px-3 py-1 rounded-md border transition-all duration-200">
                    Vorige
                  </button>
                  <button 
                    className="px-3 py-1 rounded-md border transition-all duration-200"
                    style={{ 
                      backgroundColor: 'var(--color-bg-primary)', 
                      borderColor: 'var(--color-border)',
                      color: 'var(--color-text-primary)'
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = 'var(--color-hover-light)';
                      e.currentTarget.style.borderColor = 'var(--color-accent)';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'var(--color-bg-primary)';
                      e.currentTarget.style.borderColor = 'var(--color-border)';
                    }}
                  >
                    1
                  </button>
                  <button disabled style={{ 
                    backgroundColor: 'var(--color-bg-tertiary)', 
                    borderColor: 'var(--color-border)',
                    color: 'var(--color-text-tertiary)'
                  }} className="px-3 py-1 rounded-md border transition-all duration-200">
                    Volgende
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </main>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        title="Order verwijderen"
        message={`Weet u zeker dat u order ${selectedOrder?.orderNumber} wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.`}
        itemName={selectedOrder?.orderNumber}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
      />
      
      {/* Email Modal */}
      {showEmailModal && selectedOrder && (
        <EmailModal
          order={selectedOrder}
          customer={selectedOrder.customer}
          onClose={() => setShowEmailModal(false)}
          onSuccess={handleEmailSuccess}
        />
      )}
    </>
  );
};

export default Inkooporders;