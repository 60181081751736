// src/pages/Klanten.js
import React, { useState, useEffect } from 'react';
import { Plus, Edit3, Trash2, Mail, Phone, MapPin, User } from 'lucide-react';
import { getCustomers, deleteCustomer } from '../services/customerService';
import CustomerForm from '../components/CustomerForm';
import DeleteModal from '../components/DeleteModal';

const Klanten = () => {
  const [klanten, setKlanten] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const data = await getCustomers();
      setKlanten(data);
      setError('');
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van de klanten.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (customer) => {
    setSelectedCustomer(customer);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!selectedCustomer) return;
    
    try {
      await deleteCustomer(selectedCustomer._id);
      setKlanten(klanten.filter(k => k._id !== selectedCustomer._id));
      setShowDeleteModal(false);
      setSelectedCustomer(null);
    } catch (err) {
      setError('Er is een fout opgetreden bij het verwijderen van de klant.');
      console.error(err);
    }
  };

  const handleNewCustomer = () => {
    setEditingCustomer(null);
    setShowForm(true);
  };

  const handleEditCustomer = (customer) => {
    setEditingCustomer(customer);
    setShowForm(true);
  };

  const handleFormSuccess = () => {
    setShowForm(false);
    setEditingCustomer(null);
    fetchCustomers();
  };

  // Filter customers
  const filteredKlanten = klanten.filter(klant => {
    const matchesSearch = 
      klant.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
      klant.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      klant.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (klant.city && klant.city.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesActive = showActiveOnly ? klant.active : true;
    
    return matchesSearch && matchesActive;
  });

  return (
    <>
      {/* Header */}
      <header style={{ backgroundColor: 'var(--color-bg-primary)', borderBottom: '1px solid var(--color-border)' }} 
              className="shadow-sm p-4 flex flex-col sm:flex-row justify-between items-center gap-4">
        <h2 style={{ color: 'var(--color-text-primary)' }} className="text-2xl font-semibold">Klanten</h2>
        <div className="flex flex-wrap gap-2 w-full sm:w-auto">
          <button 
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center text-white transition-all duration-200"
            style={{ 
              backgroundColor: 'var(--color-accent)',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onClick={handleNewCustomer}
            onMouseOver={(e) => {
              // Use a darker background color for better contrast with white text
              e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
              e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
              e.currentTarget.style.transform = 'translateY(-1px)';
              // Make text brighter for better contrast
              e.currentTarget.style.color = '#ffffff';
              e.currentTarget.style.fontWeight = '600';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-accent)';
              e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.fontWeight = 'normal';
            }}
          >
            <Plus className="mr-2" size={18} /> Nieuwe Klant
          </button>
          <button 
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center transition-all duration-200"
            style={{ 
              backgroundColor: 'var(--color-bg-tertiary)', 
              color: 'var(--color-text-primary)',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-hover-btn)';
              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.transform = 'translateY(-1px)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-bg-tertiary)';
              e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            Exporteren
          </button>
        </div>
      </header>
      
      {/* Content Area */}
      <main style={{ backgroundColor: 'var(--color-bg-secondary)' }} className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50 p-4 sm:p-6">
        {error && (
          <div style={{ 
            backgroundColor: 'rgba(220, 38, 38, 0.1)',
            borderColor: 'var(--color-error)',
            color: 'var(--color-error)'
          }} className="border rounded mb-6 px-4 py-3">
            {error}
          </div>
        )}

        {/* Filters */}
        <div style={{ 
          backgroundColor: 'var(--color-bg-primary)',
          borderColor: 'var(--color-border)'
        }} className="shadow-md rounded-lg p-4 mb-6">
          <div className="flex flex-col sm:flex-row sm:flex-wrap gap-4 items-start sm:items-center">
            <div className="w-full sm:flex-1 sm:min-w-[200px]">
              <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium text-gray-700 mb-1">Zoeken</label>
              <input 
                type="text" 
                placeholder="Zoek op naam, bedrijf, email of plaats..." 
                className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                style={{ 
                  backgroundColor: 'var(--color-input-bg)',
                  borderColor: 'var(--color-input-border)',
                  color: 'var(--color-text-primary)'
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="flex items-center pt-0 sm:pt-6 mb-1 sm:mb-0">
              <label className="inline-flex items-center">
                <input 
                  type="checkbox" 
                  className="h-5 w-5" 
                  style={{ accentColor: 'var(--color-accent)' }}
                  checked={showActiveOnly}
                  onChange={() => setShowActiveOnly(!showActiveOnly)}
                />
                <span style={{ color: 'var(--color-text-primary)' }} className="ml-2">Alleen actieve klanten</span>
              </label>
            </div>
          </div>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {filteredKlanten.length > 0 ? (
              filteredKlanten.map((klant) => (
                <div key={klant._id} style={{ 
                  backgroundColor: 'var(--color-bg-primary)',
                  borderColor: 'var(--color-border)'
                }} 
                className="shadow-md rounded-lg overflow-hidden transition-all duration-200"
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = 'translateY(-4px)';
                  e.currentTarget.style.boxShadow = '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = 'translateY(0)';
                  e.currentTarget.style.boxShadow = '';
                }}>
                  <div style={{ 
                    backgroundColor: klant.active ? 'rgba(59, 130, 246, 0.1)' : 'var(--color-bg-tertiary)',
                    borderColor: 'var(--color-border)'
                  }} className="p-4 border-b flex flex-wrap justify-between items-center gap-2">
                    <h3 style={{ color: 'var(--color-text-primary)' }} className="font-semibold truncate">{klant.company}</h3>
                    <span style={{ 
                      backgroundColor: klant.active ? 'rgba(16, 185, 129, 0.2)' : 'var(--color-bg-tertiary)',
                      color: klant.active ? 'var(--color-success)' : 'var(--color-text-secondary)'
                    }} className="inline-flex px-2 py-1 text-xs font-medium rounded-full">
                      {klant.active ? 'Actief' : 'Inactief'}
                    </span>
                  </div>
                  
                  <div className="p-4">
                    <div className="flex items-center mb-3">
                      <User size={16} className="sm:w-[18px] sm:h-[18px] mr-2" style={{ color: 'var(--color-text-tertiary)' }} />
                      <span style={{ color: 'var(--color-text-primary)' }} className="truncate">{klant.name}</span>
                    </div>
                    
                    <div className="flex items-center mb-3">
                      <Mail size={16} className="sm:w-[18px] sm:h-[18px] mr-2" style={{ color: 'var(--color-text-tertiary)' }} />
                      <span style={{ color: 'var(--color-text-primary)' }} className="truncate">{klant.email}</span>
                    </div>
                    
                    {klant.phone && (
                      <div className="flex items-center mb-3">
                        <Phone size={16} className="sm:w-[18px] sm:h-[18px] mr-2" style={{ color: 'var(--color-text-tertiary)' }} />
                        <span style={{ color: 'var(--color-text-primary)' }}>{klant.phone}</span>
                      </div>
                    )}
                    
                    {(klant.address || klant.postalCode || klant.city) && (
                      <div className="flex items-start mb-3">
                        <MapPin size={16} className="sm:w-[18px] sm:h-[18px] mr-2 mt-1" style={{ color: 'var(--color-text-tertiary)' }} />
                        <div className="overflow-hidden">
                          {klant.address && <p style={{ color: 'var(--color-text-primary)' }} className="truncate">{klant.address}</p>}
                          {(klant.postalCode || klant.city) && (
                            <p style={{ color: 'var(--color-text-primary)' }} className="truncate">{klant.postalCode} {klant.city}</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <div style={{ 
                    backgroundColor: 'var(--color-bg-tertiary)',
                    borderColor: 'var(--color-border)'
                  }} className="p-4 bg-gray-50 border-t flex justify-end space-x-2">
                    <button 
                      className="p-1 rounded-md transition-all duration-200"
                      style={{ color: 'var(--color-accent)' }}
                      onClick={() => handleEditCustomer(klant)}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = 'var(--color-hover-primary)';
                        e.currentTarget.style.transform = 'scale(1.1)';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '';
                        e.currentTarget.style.transform = 'scale(1)';
                      }}
                    >
                      <Edit3 size={16} className="sm:w-[18px] sm:h-[18px]" />
                    </button>
                    <button 
                      className="p-1 rounded-md transition-all duration-200"
                      style={{ color: 'var(--color-success)' }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = 'var(--color-hover-success)';
                        e.currentTarget.style.transform = 'scale(1.1)';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '';
                        e.currentTarget.style.transform = 'scale(1)';
                      }}
                    >
                      <Mail size={16} className="sm:w-[18px] sm:h-[18px]" />
                    </button>
                    <button 
                      className="p-1 rounded-md transition-all duration-200"
                      style={{ color: 'var(--color-error)' }}
                      onClick={() => handleDeleteClick(klant)}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = 'var(--color-hover-error)';
                        e.currentTarget.style.transform = 'scale(1.1)';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '';
                        e.currentTarget.style.transform = 'scale(1)';
                      }}
                    >
                      <Trash2 size={16} className="sm:w-[18px] sm:h-[18px]" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ 
                backgroundColor: 'var(--color-bg-primary)',
                color: 'var(--color-text-secondary)'
              }} className="col-span-1 sm:col-span-2 lg:col-span-3 p-6 sm:p-8 rounded-lg shadow text-center">
                <div className="mb-4">Geen klanten gevonden</div>
                <button 
                  className="px-4 py-2 rounded-md flex items-center mx-auto text-white transition-all duration-200"
                  style={{ 
                    backgroundColor: 'var(--color-accent)',
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                  }}
                  onClick={handleNewCustomer}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
                    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
                    e.currentTarget.style.transform = 'translateY(-1px)';
                    e.currentTarget.style.color = '#ffffff';
                    e.currentTarget.style.fontWeight = '600';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                    e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.color = 'white';
                    e.currentTarget.style.fontWeight = 'normal';
                  }}
                >
                  <Plus className="mr-2" size={18} /> Voeg uw eerste klant toe
                </button>
              </div>
            )}
          </div>
        )}
      </main>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        title="Klant verwijderen"
        message={`Weet u zeker dat u ${selectedCustomer?.name} wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.`}
        itemName={selectedCustomer?.name}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
      />

      {/* Customer Form Modal */}
      {showForm && (
        <CustomerForm 
          customer={editingCustomer}
          onClose={() => setShowForm(false)}
          onSuccess={handleFormSuccess}
        />
      )}
    </>
  );
};

export default Klanten;