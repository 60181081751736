import React from 'react';

/**
 * Reusable form input component with integrated validation support
 * 
 * @param {Object} props - Component props
 * @param {string} props.label - Input label text
 * @param {string} props.name - Input name (for form registration)
 * @param {string} props.type - Input type (text, email, password, etc.)
 * @param {Object} props.register - React Hook Form register function
 * @param {Object} props.validation - Validation rules for this input
 * @param {Object} props.errors - React Hook Form errors object
 * @param {string} props.placeholder - Input placeholder text
 * @param {boolean} props.required - Whether input is required
 * @param {Object} props.styles - Custom styles for the component
 */
const FormInput = ({
  label,
  name,
  type = 'text',
  register,
  validation,
  errors,
  placeholder = '',
  required = false,
  styles = {},
  ...rest
}) => {
  // Default styles that can be overridden
  const defaultStyles = {
    input: "w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent",
    inputError: "border-red-500",
    label: "block text-sm font-medium mb-1",
    error: "mt-1 text-sm text-red-600"
  };

  // Merge default styles with custom styles
  const mergedStyles = {
    input: `${defaultStyles.input} ${styles.input || ''}`,
    inputError: `${defaultStyles.inputError} ${styles.inputError || ''}`,
    label: `${defaultStyles.label} ${styles.label || ''}`,
    error: `${defaultStyles.error} ${styles.error || ''}`
  };

  // Get appropriate background and text colors based on theme
  const themeStyles = {
    backgroundColor: 'var(--color-input-bg)',
    borderColor: errors[name] ? 'var(--color-error)' : 'var(--color-input-border)',
    color: 'var(--color-text-primary)'
  };

  return (
    <div className="mb-4">
      {label && (
        <label 
          htmlFor={name} 
          className={mergedStyles.label}
          style={{ color: 'var(--color-text-secondary)' }}
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      
      <input
        id={name}
        type={type}
        placeholder={placeholder}
        className={`${mergedStyles.input} ${errors[name] ? mergedStyles.inputError : ''}`}
        style={themeStyles}
        {...register(name, validation)}
        {...rest}
      />
      
      {errors[name] && (
        <p className={mergedStyles.error} style={{ color: 'var(--color-error)' }}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export default FormInput; 