/**
 * Error logging service for the frontend
 * Provides centralized error handling, logging, and reporting
 */

// Can be expanded to send errors to a backend API or a third-party service like Sentry
const logError = (error, errorInfo = null) => {
  // Always log to console in development
  if (process.env.NODE_ENV === 'development') {
    console.error('Error occurred:', error);
    if (errorInfo) {
      console.error('Error Info:', errorInfo);
    }
  }

  // In production, you could send to a logging endpoint
  if (process.env.NODE_ENV === 'production') {
    // Example of sending to a backend API
    try {
      // Sanitize error data before sending
      const errorData = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        timestamp: new Date().toISOString(),
        url: window.location.href,
        userAgent: navigator.userAgent,
        additionalInfo: errorInfo
      };
      
      // We can implement this endpoint later
      // fetch('/api/log-error', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(errorData)
      // });

      // Could also integrate with a service like Sentry here
    } catch (loggingError) {
      // Fallback to console in case the logging itself fails
      console.error('Failed to log error:', loggingError);
    }
  }
};

// Handles API error responses
const handleApiError = (error) => {
  // Log the error
  logError(error);
  
  // Extract error message from API response or use a default
  let errorMessage = 'An unexpected error occurred';
  
  if (error.response) {
    // The request was made and the server responded with an error status
    errorMessage = error.response.data?.error || error.response.data?.message || `Error ${error.response.status}: ${error.response.statusText}`;
  } else if (error.request) {
    // The request was made but no response received
    errorMessage = 'No response received from server. Please check your internet connection.';
  } else {
    // Something happened in setting up the request
    errorMessage = error.message || errorMessage;
  }
  
  return errorMessage;
};

export { logError, handleApiError }; 