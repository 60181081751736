// src/components/DeleteModal.js
import React from 'react';

const DeleteModal = ({ title, message, onCancel, onConfirm, isOpen, itemName }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4" style={{ backgroundColor: 'var(--color-modal-overlay)' }}>
      <div className="w-full max-w-md rounded-lg shadow-xl p-4 sm:p-6" style={{ 
        backgroundColor: 'var(--color-bg-primary)',
        borderColor: 'var(--color-border)' 
      }}>
        <h3 className="text-lg font-bold mb-4" style={{ color: 'var(--color-text-primary)' }}>
          {title || 'Bevestiging'}
        </h3>
        <p className="mb-6" style={{ color: 'var(--color-text-secondary)' }}>
          {message || `Weet u zeker dat u ${itemName ? `"${itemName}"` : 'dit item'} wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.`}
        </p>
        <div className="flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0 sm:space-x-3">
          <button 
            className="px-4 py-2 border rounded-md w-full sm:w-auto"
            style={{ 
              backgroundColor: 'transparent',
              borderColor: 'var(--color-border)',
              color: 'var(--color-text-primary)'
            }}
            onClick={onCancel}
          >
            Annuleren
          </button>
          <button 
            className="px-4 py-2 text-white rounded-md w-full sm:w-auto"
            style={{ backgroundColor: 'var(--color-error)' }}
            onClick={onConfirm}
          >
            Verwijderen
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;