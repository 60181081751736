/**
 * Frontend validation schemas for React Hook Form
 * These schemas are similar to the backend Joi schemas but adapted for the frontend
 */

// Validation rules for customer forms
export const customerValidation = {
  name: {
    required: 'Naam is verplicht',
    minLength: {
      value: 2,
      message: 'Naam moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 100,
      message: 'Naam mag maximaal 100 tekens bevatten'
    }
  },
  company: {
    required: 'Bedrijfsnaam is verplicht',
    minLength: {
      value: 2,
      message: 'Bedrijfsnaam moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 100,
      message: 'Bedrijfsnaam mag maximaal 100 tekens bevatten'
    }
  },
  email: {
    required: 'E-mailadres is verplicht',
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Voer een geldig e-mailadres in'
    }
  },
  phone: {
    maxLength: {
      value: 20,
      message: 'Telefoonnummer mag maximaal 20 tekens bevatten'
    }
  },
  address: {
    maxLength: {
      value: 200,
      message: 'Adres mag maximaal 200 tekens bevatten'
    }
  },
  postalCode: {
    maxLength: {
      value: 10,
      message: 'Postcode mag maximaal 10 tekens bevatten'
    }
  },
  city: {
    maxLength: {
      value: 100,
      message: 'Plaats mag maximaal 100 tekens bevatten'
    }
  },
  kvkNumber: {
    pattern: {
      value: /^\d{8}$/,
      message: 'KVK-nummer moet uit 8 cijfers bestaan'
    }
  },
  btwNumber: {
    pattern: {
      value: /^NL\d{9}B\d{2}$/,
      message: 'BTW-nummer moet in formaat NL123456789B01 zijn'
    }
  }
};

// Validation rules for login form
export const loginValidation = {
  email: {
    required: 'E-mailadres is verplicht',
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Voer een geldig e-mailadres in'
    }
  },
  password: {
    required: 'Wachtwoord is verplicht'
  }
};

// Validation rules for registration form
export const registerValidation = {
  name: {
    required: 'Naam is verplicht',
    minLength: {
      value: 2,
      message: 'Naam moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 100,
      message: 'Naam mag maximaal 100 tekens bevatten'
    }
  },
  email: {
    required: 'E-mailadres is verplicht',
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Voer een geldig e-mailadres in'
    }
  },
  password: {
    required: 'Wachtwoord is verplicht',
    minLength: {
      value: 8,
      message: 'Wachtwoord moet minimaal 8 tekens bevatten'
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
      message: 'Wachtwoord moet minimaal één hoofdletter, één kleine letter en één cijfer bevatten'
    }
  },
  confirmPassword: {
    validate: (value, formValues) => 
      !value || value === formValues.password || 'Wachtwoorden komen niet overeen'
  },
  company: {
    required: 'Bedrijfsnaam is verplicht',
    minLength: {
      value: 2,
      message: 'Bedrijfsnaam moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 100,
      message: 'Bedrijfsnaam mag maximaal 100 tekens bevatten'
    }
  }
};

// Validation rules for order item
export const orderItemValidation = {
  description: {
    required: 'Productomschrijving is verplicht',
    minLength: {
      value: 2,
      message: 'Productomschrijving moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 200,
      message: 'Productomschrijving mag maximaal 200 tekens bevatten'
    }
  },
  quantity: {
    required: 'Aantal is verplicht',
    min: {
      value: 1,
      message: 'Aantal moet minimaal 1 zijn'
    }
  },
  price: {
    required: 'Prijs is verplicht',
    min: {
      value: 0,
      message: 'Prijs moet minimaal 0 zijn'
    }
  },
  tax: {
    required: 'BTW percentage is verplicht',
    min: {
      value: 0,
      message: 'BTW percentage moet tussen 0 en 100 zijn'
    },
    max: {
      value: 100,
      message: 'BTW percentage moet tussen 0 en 100 zijn'
    }
  }
};

// Validation rules for order form
export const orderValidation = {
  customer: {
    required: 'Klant is verplicht'
  },
  orderNumber: {
    required: 'Ordernummer is verplicht',
    minLength: {
      value: 1,
      message: 'Ordernummer moet minimaal 1 teken bevatten'
    },
    maxLength: {
      value: 50,
      message: 'Ordernummer mag maximaal 50 tekens bevatten'
    }
  },
  orderDate: {
    required: 'Orderdatum is verplicht'
  },
  notes: {
    maxLength: {
      value: 1000,
      message: 'Notities mogen maximaal 1000 tekens bevatten'
    }
  },
  reference: {
    maxLength: {
      value: 100,
      message: 'Referentie mag maximaal 100 tekens bevatten'
    }
  }
};

// Validation rules for email form
export const emailValidation = {
  to: {
    required: 'E-mailadres is verplicht',
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Voer een geldig e-mailadres in'
    }
  },
  subject: {
    required: 'Onderwerp is verplicht',
    minLength: {
      value: 2,
      message: 'Onderwerp moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 200,
      message: 'Onderwerp mag maximaal 200 tekens bevatten'
    }
  },
  body: {
    required: 'Bericht is verplicht',
    minLength: {
      value: 2,
      message: 'Bericht moet minimaal 2 tekens bevatten'
    },
    maxLength: {
      value: 5000,
      message: 'Bericht mag maximaal 5000 tekens bevatten'
    }
  }
}; 