// src/components/PDFPreview.js
import React from 'react';

const PDFPreview = ({ companyInfo, templateStyle = 'classic', primaryColor = '#1E40AF', secondaryColor = '#F3F4F6', showBankAccount = true, footerNotes = '' }) => {
  // Format the company details for the footer
  const getFooterText = () => {
    let text = companyInfo.company;
    
    if (companyInfo.address) {
      text += ` | ${companyInfo.address}`;
    }
    
    if (companyInfo.postalCode || companyInfo.city) {
      text += ` | ${companyInfo.postalCode} ${companyInfo.city}`;
    }
    
    text += ` | ${companyInfo.email}`;
    
    if (companyInfo.website) {
      text += ` | ${companyInfo.website}`;
    }
    
    if (companyInfo.kvkNumber) {
      text += ` | KVK: ${companyInfo.kvkNumber}`;
    }
    
    if (companyInfo.btwNumber) {
      text += ` | BTW: ${companyInfo.btwNumber}`;
    }
    
    // Add footer notes if provided
    if (footerNotes) {
      text += ` | ${footerNotes}`;
    }
    
    return text;
  };

  // Define a table row with explicit styles for dark text
  const TableRow = ({ description, quantity, unit, price, vat, total, isAlternate }) => {
    // Change background color for alternating rows based on the template style
    const bgColor = isAlternate ? 
      (templateStyle === 'minimal' ? 'white' : '#f9fafb') : 
      'white';
    
    // Change border style based on template
    const borderStyle = templateStyle === 'minimal' ? 
      { borderBottom: '1px solid #e5e7eb', borderLeft: 'none', borderRight: 'none', borderTop: 'none' } : 
      { border: '1px solid #d1d5db' };
      
    return (
      <tr style={{ backgroundColor: bgColor }}>
        <td style={{ padding: '8px', textAlign: 'left', color: '#111827', ...borderStyle }}>
          {description}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {quantity}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {unit}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {price}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {vat}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {total}
        </td>
      </tr>
    );
  };

  // Render different header styles based on template
  const renderHeader = () => {
    switch (templateStyle) {
      case 'modern':
        return (
          <>
            {/* Modern header with color bar */}
            <div style={{ 
              position: 'relative', 
              marginBottom: '24px', 
              paddingTop: '8px' 
            }}>
              {/* Color bar at top */}
              <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                height: '8px', 
                backgroundColor: primaryColor 
              }}></div>
              
              {/* Company name */}
              <div style={{ marginTop: '16px' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: primaryColor }}>{companyInfo.company}</h2>
              </div>
              
              {/* Two-column layout for company and customer info */}
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                marginTop: '16px',
                gap: '24px'
              }}>
                {/* Company info */}
                <div style={{ flex: 1 }}>
                  <h3 style={{ fontSize: '13px', fontWeight: 'bold', color: '#666666', marginBottom: '8px' }}>BEDRIJFSINFORMATIE</h3>
                  <div style={{ fontSize: '12px', color: '#333333' }}>
                    <p>{companyInfo.name}</p>
                    {companyInfo.address && <p>{companyInfo.address}</p>}
                    {(companyInfo.postalCode || companyInfo.city) && 
                      <p>{companyInfo.postalCode} {companyInfo.city}</p>
                    }
                    <p>{companyInfo.country || 'Nederland'}</p>
                    <p>Email: {companyInfo.email}</p>
                    {companyInfo.phoneNumber && <p>Tel: {companyInfo.phoneNumber}</p>}
                    {companyInfo.website && <p>Website: {companyInfo.website}</p>}
                  </div>
                </div>
                
                {/* Customer info */}
                <div style={{ flex: 1 }}>
                  <h3 style={{ fontSize: '13px', fontWeight: 'bold', color: '#666666', marginBottom: '8px' }}>FACTUUR AAN</h3>
                  <div style={{ fontSize: '12px', color: '#333333' }}>
                    <p>Klantnaam BV</p>
                    <p>t.a.v. Contactpersoon</p>
                    <p>Klantadres 123</p>
                    <p>1234 AB Plaats</p>
                    <p>KVK: 12345678</p>
                    <p>BTW: NL123456789B01</p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Document title with accent bar */}
            <div style={{ 
              position: 'relative', 
              marginTop: '12px',
              marginBottom: '16px',
              paddingLeft: '16px',
              height: '22px'
            }}>
              {/* Accent rectangle */}
              <div style={{ 
                position: 'absolute', 
                left: 0, 
                top: 0, 
                width: '8px', 
                height: '22px', 
                backgroundColor: primaryColor 
              }}></div>
              
              {/* Title */}
              <h2 style={{ 
                fontSize: '18px', 
                fontWeight: 'bold', 
                color: '#333333',
                lineHeight: '22px'
              }}>INKOOPORDER</h2>
            </div>
            
            {/* Order details in vertical layout */}
            <div style={{ 
              marginTop: '16px', 
              marginBottom: '16px', 
              fontSize: '13px', 
              color: '#333333' 
            }}>
              {/* Order Number */}
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Ordernummer:</div>
                <div>2025XXXX</div>
              </div>
              
              {/* Date */}
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Datum:</div>
                <div>01-05-2025</div>
              </div>
              
              {/* Payment Term */}
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Betaaltermijn:</div>
                <div>14 dagen</div>
              </div>
              
              {/* Project */}
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Project:</div>
                <div>Voorbeeld Project</div>
              </div>
              
              {/* Reference */}
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Referentie:</div>
                <div>REF123</div>
              </div>
            </div>
            
            {/* Separator line before items table */}
            <div style={{ 
              borderBottom: '1px solid #E5E7EB', 
              marginBottom: '16px' 
            }}></div>
          </>
        );
        
      case 'minimal':
        return (
          <>
            {/* Minimal header */}
            <div style={{ marginBottom: '24px' }}>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                marginBottom: '16px'
              }}>
                <div>
                  <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: primaryColor }}>{companyInfo.company}</h2>
                  <h3 style={{ fontSize: '16px', color: primaryColor, marginTop: '4px' }}>INKOOPORDER</h3>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <h3 style={{ fontWeight: 'bold', fontSize: '14px', color: '#111827' }}>FACTUUR AAN:</h3>
                  <div style={{ marginTop: '4px', fontSize: '13px', color: '#4b5563', textAlign: 'right' }}>
                    <p>Klantnaam BV</p>
                    <p>t.a.v. Contactpersoon</p>
                  </div>
                </div>
              </div>
              
              {/* Compact order details */}
              <div style={{ fontSize: '13px', color: '#4b5563', marginBottom: '16px' }}>
                <p>Ordernummer: 2025XXXX | Datum: 01-05-2025 | Referentie: REF123 | Betaaltermijn: 14 dagen</p>
              </div>
              
              <div style={{ borderBottom: '1px solid #e5e7eb', paddingBottom: '8px' }}></div>
            </div>
          </>
        );
        
      default: // Classic
        return (
          <>
            {/* Classic header */}
            <div className="mb-6">
              <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: primaryColor }}>{companyInfo.company}</h2>
              <div style={{ marginTop: '16px', fontSize: '14px', color: '#4b5563' }}>
                <p>{companyInfo.name}</p>
                {companyInfo.address && <p>{companyInfo.address}</p>}
                {(companyInfo.postalCode || companyInfo.city) && 
                  <p>{companyInfo.postalCode} {companyInfo.city}</p>
                }
                <p>{companyInfo.country || 'Nederland'}</p>
                <p>Email: {companyInfo.email}</p>
                {companyInfo.phoneNumber && <p>Tel: {companyInfo.phoneNumber}</p>}
                {companyInfo.website && <p>Website: {companyInfo.website}</p>}
              </div>
            </div>
            
            <div style={{ marginTop: '32px' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '1' }}></div>
                <div style={{ width: '256px' }}>
                  <h3 style={{ fontWeight: 'bold', fontSize: '16px', color: '#111827' }}>FACTUUR AAN:</h3>
                  <div style={{ marginTop: '8px', fontSize: '14px', color: '#4b5563' }}>
                    <p>Klantnaam BV</p>
                    <p>t.a.v. Contactpersoon</p>
                    <p>Klantadres 123</p>
                    <p>1234 AB Plaats</p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Order Header */}
            <div style={{ marginTop: '32px' }}>
              <h2 style={{ fontSize: '20px', fontWeight: 'bold', color: primaryColor }}>INKOOPORDER</h2>
            </div>
            
            {/* Order Details */}
            <div style={{ marginTop: '16px', fontSize: '14px', color: '#111827' }}>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Ordernummer:</p>
                  <p style={{ color: '#111827' }}>2025XXXX</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Referentie:</p>
                  <p style={{ color: '#111827' }}>REF123</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Datum:</p>
                  <p style={{ color: '#111827' }}>01-05-2025</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Project:</p>
                  <p style={{ color: '#111827' }}>Voorbeeld Project</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Betaaltermijn:</p>
                  <p style={{ color: '#111827' }}>14 dagen</p>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  // Render different table header styles based on template
  const renderTableHeader = () => {
    const commonThStyle = { 
      padding: '8px', 
      fontWeight: 'bold',
      color: '#111827'
    };
    
    // Customize based on template style
    switch (templateStyle) {
      case 'modern':
        return (
          <tr>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'left',
              borderRadius: '2px 0 0 2px'
            }}>
              Omschrijving
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right' 
            }}>
              Aantal
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right' 
            }}>
              Eenheid
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right' 
            }}>
              Prijs (€)
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right' 
            }}>
              BTW %
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right',
              borderRadius: '0 2px 2px 0'
            }}>
              Totaal (€)
            </th>
          </tr>
        );
        
      case 'minimal':
        return (
          <tr style={{ borderBottom: '1px solid #e5e7eb' }}>
            <th style={{ ...commonThStyle, textAlign: 'left', border: 'none' }}>Omschrijving</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Aantal</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Eenheid</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Prijs (€)</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>BTW %</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Totaal (€)</th>
          </tr>
        );
        
      default: // Classic
        return (
          <tr style={{ backgroundColor: secondaryColor }}>
            <th style={{ ...commonThStyle, textAlign: 'left', border: '1px solid #d1d5db' }}>Omschrijving</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Aantal</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Eenheid</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Prijs (€)</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>BTW %</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Totaal (€)</th>
          </tr>
        );
    }
  };

  // Render different totals section based on template
  const renderTotals = () => {
    switch (templateStyle) {
      case 'modern':
        return (
          <div style={{ 
            backgroundColor: secondaryColor, 
            borderRadius: '6px', 
            padding: '16px', 
            marginLeft: 'auto', 
            marginTop: '16px',
            width: '300px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)'
          }}>
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              marginBottom: '8px',
              fontSize: '14px',
              color: '#333333'
            }}>
              <span>Subtotaal:</span>
              <span>€ 2.680,00</span>
            </div>
            
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              marginBottom: '12px',
              fontSize: '14px',
              color: '#333333'
            }}>
              <span>BTW:</span>
              <span>€ 562,80</span>
            </div>
            
            <div style={{ 
              borderTop: `1px solid ${primaryColor}`, 
              marginBottom: '12px', 
              paddingTop: '12px', 
              paddingBottom: '4px',
              fontSize: '16px',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'space-between',
              color: primaryColor
            }}>
              <span>Totaal:</span>
              <span>€ 3.242,80</span>
            </div>
            
            {showBankAccount && (
              <div style={{ 
                fontSize: '12px',
                color: '#666666',
                marginTop: '8px'
              }}>
                <p style={{ marginBottom: '4px' }}>Bankgegevens:</p>
                <p>IBAN: NL12 INGB 0123 4567 89</p>
                <p>t.n.v. {companyInfo.company}</p>
              </div>
            )}
          </div>
        );
        
      case 'minimal':
        return (
          <div style={{ marginTop: '32px', textAlign: 'right' }}>
            <p style={{ fontSize: '14px', color: '#374151', marginBottom: '4px' }}>Subtotaal: € 425.00</p>
            <p style={{ fontSize: '14px', color: '#374151', marginBottom: '4px' }}>BTW: € 89.25</p>
            <p style={{ fontSize: '16px', fontWeight: 'bold', color: primaryColor, marginTop: '8px' }}>Totaal: € 514.25</p>
          </div>
        );
        
      default: // Classic
        return (
          <div style={{ marginTop: '32px', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: '256px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                <span style={{ fontSize: '14px', fontWeight: '500', color: '#374151' }}>Subtotaal:</span>
                <span style={{ fontSize: '14px', color: '#374151' }}>€ 425.00</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                <span style={{ fontSize: '14px', fontWeight: '500', color: '#374151' }}>BTW:</span>
                <span style={{ fontSize: '14px', color: '#374151' }}>€ 89.25</span>
              </div>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                fontWeight: 'bold', 
                borderTop: '1px solid #9ca3af', 
                paddingTop: '4px', 
                marginTop: '4px' 
              }}>
                <span style={{ color: primaryColor }}>Totaal:</span>
                <span style={{ color: primaryColor }}>€ 514.25</span>
              </div>
            </div>
          </div>
        );
    }
  };

  // Render footer based on template
  const renderFooter = () => {
    const footerStyle = {
      marginTop: '64px', 
      paddingTop: '16px', 
      borderTop: '1px solid #d1d5db', 
      textAlign: 'center', 
      fontSize: '12px', 
      color: '#6b7280'
    };
    
    // Bank account info if enabled
    const bankInfo = showBankAccount ? (
      <p style={{ marginTop: '8px' }}>IBAN: NL01 BANK 0123 4567 89 | BIC: BANKNL2A</p>
    ) : null;
    
    return (
      <div style={footerStyle}>
        <p>{getFooterText()}</p>
        {bankInfo}
      </div>
    );
  };

  return (
    <div style={{ 
      backgroundColor: 'var(--color-bg-primary)',
      borderColor: 'var(--color-border)'
    }} className="border rounded-lg shadow-md overflow-hidden">
      <div style={{ 
        backgroundColor: 'var(--color-bg-tertiary)',
        borderBottom: '1px solid var(--color-border)'
      }} className="p-4 flex justify-between items-center">
        <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">Voorbeeld PDF Layout</h3>
        <span style={{ color: 'var(--color-text-secondary)' }} className="text-sm">
          {templateStyle === 'classic' ? 'Klassiek' : templateStyle === 'modern' ? 'Modern' : 'Minimaal'} template
        </span>
      </div>

      <div className="p-4">
        {/* PDF Preview - ALWAYS use white background and dark text for the PDF content regardless of theme */}
        <div style={{ 
          backgroundColor: 'white',
          color: '#111827',
          border: '1px solid #e5e7eb'
        }} className="rounded-lg overflow-hidden shadow p-6">
          {/* Header - varies by template */}
          {renderHeader()}
          
          {/* Items Table - with explicit styling for visibility */}
          <div style={{ marginTop: templateStyle === 'minimal' ? '16px' : '32px' }}>
            <div style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', fontSize: '14px', borderCollapse: 'collapse' }}>
                <thead>
                  {renderTableHeader()}
                </thead>
                <tbody>
                  <TableRow 
                    description="Voorbeeld artikel 1"
                    quantity="2"
                    unit="stuk"
                    price="100.00"
                    vat="21%"
                    total="200.00"
                    isAlternate={false}
                  />
                  <TableRow 
                    description="Voorbeeld artikel 2"
                    quantity="5"
                    unit="uur"
                    price="45.00"
                    vat="21%"
                    total="225.00"
                    isAlternate={true}
                  />
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Totals - varies by template */}
          {renderTotals()}
          
          {/* Footer - common layout but text may vary */}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default PDFPreview;