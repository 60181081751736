import React from 'react';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] p-6 bg-red-50 rounded-lg shadow-sm border border-red-200">
      <div className="w-full max-w-md text-center">
        <div className="text-red-600 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        
        <h2 className="text-xl font-semibold text-gray-800 mb-2">Something went wrong</h2>
        
        <div className="mb-6">
          <p className="text-gray-600 mb-4">
            An unexpected error has occurred. Our team has been notified.
          </p>
          
          <div className="bg-white p-3 rounded border border-red-200 text-left mb-4 overflow-auto max-h-32">
            <p className="text-sm font-mono text-red-600">
              {error.message || 'Unknown error'}
            </p>
          </div>
        </div>
        
        <button
          onClick={resetErrorBoundary}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Try again
        </button>
      </div>
    </div>
  );
};

export default ErrorFallback; 