import axios from 'axios';
import { logError } from './errorService';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// Create a base API instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(
  (config) => {
    // Get token from the user object
    const userStr = localStorage.getItem('user');
    
    if (userStr) {
      try {
        const user = JSON.parse(userStr);
        if (user && user.token) {
          config.headers['Authorization'] = `Bearer ${user.token}`;
        }
      } catch (error) {
        // Log error with our error service
        logError(error, { message: 'Failed to parse user data from localStorage', context: 'api.interceptors.request' });
      }
    }
    return config;
  },
  (error) => {
    logError(error, { message: 'Request interceptor error', context: 'api.interceptors.request' });
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log error with our error service
    logError(error, { 
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      context: 'api.interceptors.response'
    });

    // Handle unauthorized errors (401)
    if (error.response && error.response.status === 401) {
      // Clear user data if the token is invalid or expired
      localStorage.removeItem('user');
      // Could also redirect to login page here
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default api;