import React, { useState, useEffect } from 'react';
import { getOrderNumberSettings, updateOrderNumberSettings } from '../services/authService';

const OrderNumberSettings = () => {
  const [settings, setSettings] = useState({
    format: 'year',
    prefix: '',
    includeYear: true,
    startingNumber: 1001
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [previewNumber, setPreviewNumber] = useState('');

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    // Generate a preview of the order number format
    generatePreview();
  }, [settings]);

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const data = await getOrderNumberSettings();
      
      // Use the settings from the server or defaults
      setSettings({
        format: data?.format || 'year',
        prefix: data?.prefix || '',
        includeYear: data?.includeYear !== undefined ? data.includeYear : true,
        startingNumber: data?.startingNumber || 1001
      });
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van de instellingen.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const generatePreview = () => {
    const currentYear = new Date().getFullYear();
    let preview = '';
    
    // Add prefix if it exists
    if (settings.prefix) {
      preview += settings.prefix;
    }
    
    // Add year if the format includes it
    if (settings.includeYear) {
      preview += currentYear.toString();
    }
    
    // Add the starting number
    preview += settings.startingNumber.toString();
    
    setPreviewNumber(preview);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setSettings(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Clear any existing messages
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsSaving(true);
      setError('');
      setSuccess('');
      
      await updateOrderNumberSettings(settings);
      
      // Scroll to the top of the form
      const formElement = document.getElementById('orderNumberSettingsForm');
      if (formElement) {
        formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      
      // Set success message
      setSuccess('Instellingen opgeslagen! Nieuwe orders zullen deze nummering gebruiken.');
      
      // Add a flash animation to highlight the success message
      const successElement = document.getElementById('orderNumberSettingsSuccess');
      if (successElement) {
        successElement.classList.add('animate-pulse');
        setTimeout(() => {
          successElement.classList.remove('animate-pulse');
        }, 1500);
      }
      
      // Reset success message after 5 seconds
      setTimeout(() => {
        setSuccess('');
      }, 5000);
    } catch (err) {
      setError('Er is een fout opgetreden bij het opslaan van de instellingen.');
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-32">
        <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2"></div>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg overflow-hidden">
      <div style={{ backgroundColor: 'var(--color-bg-tertiary)', borderBottom: '1px solid var(--color-border)' }} className="p-4">
        <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">Ordernummering</h3>
      </div>
      
      <form id="orderNumberSettingsForm" onSubmit={handleSubmit} className="p-4 sm:p-6">
        {error && (
          <div style={{ 
            backgroundColor: 'rgba(220, 38, 38, 0.1)',
            borderColor: 'var(--color-error)',
            color: 'var(--color-error)'
          }} className="border rounded mb-6 px-4 py-3">
            {error}
          </div>
        )}
        
        {success && (
          <div 
            id="orderNumberSettingsSuccess"
            style={{ 
              backgroundColor: 'rgba(16, 185, 129, 0.1)',
              borderColor: 'var(--color-success)',
              color: 'var(--color-success)',
              animation: 'slideDown 0.5s ease-out forwards'
            }} 
            className="border rounded mb-6 px-4 py-3 flex items-center"
          >
            <svg className="h-5 w-5 mr-2 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{success}</span>
            <style jsx>{`
              @keyframes slideDown {
                0% {
                  opacity: 0;
                  transform: translateY(-20px);
                }
                100% {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
              .animate-pulse {
                animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1);
              }
              @keyframes pulse {
                0%, 100% {
                  opacity: 1;
                }
                50% {
                  opacity: 0.7;
                }
              }
            `}</style>
          </div>
        )}
        
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row sm:items-center mb-4">
            <span style={{ color: 'var(--color-text-primary)' }} className="text-lg font-medium mr-2 mb-1 sm:mb-0">Voorbeeldnummer:</span>
            <span style={{ color: 'var(--color-accent)' }} className="text-xl font-bold">{previewNumber}</span>
          </div>
          
          <p style={{ color: 'var(--color-text-secondary)' }} className="text-sm">
            Leg hier vast hoe de ordernummers moeten worden opgebouwd. Wijzigingen gelden alleen voor nieuwe orders.
          </p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mb-4">
          <div>
            <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-2">Voorvoegsel (optioneel)</label>
            <input 
              type="text" 
              name="prefix"
              value={settings.prefix}
              onChange={handleChange}
              placeholder="Bijv. ORD-, INV-"
              style={{ 
                backgroundColor: 'var(--color-input-bg)',
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)'
              }}
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
            />
            <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs">
              Een optionele tekst voorafgaand aan het nummer.
            </p>
          </div>
          
          <div>
            <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-2">Startnummer</label>
            <input 
              type="number" 
              name="startingNumber"
              value={settings.startingNumber}
              onChange={handleChange}
              min="1"
              style={{ 
                backgroundColor: 'var(--color-input-bg)',
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)'
              }}
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
            />
            <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs">
              Het nummer van de eerstvolgende order.
            </p>
          </div>
        </div>
        
        <div className="mb-6">
          <div className="flex items-center">
            <input 
              type="checkbox" 
              id="includeYear"
              name="includeYear"
              checked={settings.includeYear}
              onChange={handleChange}
              style={{ accentColor: 'var(--color-accent)' }} 
              className="h-5 w-5 mr-2" 
            />
            <label htmlFor="includeYear" style={{ color: 'var(--color-text-primary)' }} className="text-sm font-medium">
              Jaartal toevoegen (bijv. 2025...)
            </label>
          </div>
          <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs ml-7">
            Voegt automatisch het huidige jaartal toe aan het ordernummer.
          </p>
        </div>
        
        <div className="mt-8 flex justify-end">
          <button 
            type="submit"
            disabled={isSaving}
            style={{ 
              backgroundColor: success ? 'var(--color-success)' : 'var(--color-accent)', 
              color: 'white',
              transition: 'all 0.3s ease'
            }}
            className={`px-6 py-2 rounded-md flex items-center ${success ? 'shadow-lg' : ''}`}
            onMouseOver={(e) => {
              if (!isSaving && !success) {
                e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
                e.currentTarget.style.transform = 'translateY(-1px)';
              }
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = success ? 'var(--color-success)' : 'var(--color-accent)';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            {isSaving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Opslaan...
              </>
            ) : success ? (
              <>
                <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Opgeslagen!
              </>
            ) : (
              'Instellingen opslaan'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderNumberSettings; 