// src/services/authService.js
import api from './api';

// Register a new user
export const register = async (userData) => {
  try {
    const response = await api.post('/auth/register', userData);
    
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Login user
export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/login', { email, password });
    
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data));
      
      // Store user name and company for email templates
      if (response.data.name) {
        localStorage.setItem('userName', response.data.name);
      }
      if (response.data.company) {
        localStorage.setItem('userCompany', response.data.company);
      }
    }
    
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Logout user
export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('userName');
  localStorage.removeItem('userCompany');
};

// Get current user from localStorage
export const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

// Check if user is authenticated
export const isAuthenticated = () => {
  return !!getCurrentUser();
};

// Get user profile
export const getUserProfile = async () => {
  try {
    const response = await api.get('/auth/profile');
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Update user profile
export const updateUserProfile = async (userData) => {
  try {
    const response = await api.put('/auth/profile', userData);
    
    // Update the user data in localStorage
    const currentUser = getCurrentUser();
    if (currentUser) {
      // Merge the current user data with the updated data
      const updatedUser = { ...currentUser, ...response.data };
      // Save back to localStorage
      localStorage.setItem('user', JSON.stringify(updatedUser));
      
      // Update name and company for email templates
      if (userData.name) {
        localStorage.setItem('userName', userData.name);
      }
      if (userData.company) {
        localStorage.setItem('userCompany', userData.company);
      }
    }
    
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Get order number settings
export const getOrderNumberSettings = async () => {
  try {
    const response = await api.get('/auth/order-settings');
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Update order number settings
export const updateOrderNumberSettings = async (settings) => {
  try {
    const response = await api.put('/auth/order-settings', settings);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Get PDF settings
export const getPDFSettings = async () => {
  try {
    const response = await api.get('/auth/pdf-settings');
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Update PDF settings
export const updatePDFSettings = async (settings) => {
  try {
    const response = await api.put('/auth/pdf-settings', settings);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Resend verification email
export const resendVerificationEmail = async () => {
  try {
    const response = await api.post('/auth/resend-verification');
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};