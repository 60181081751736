import api from './api';

export const generateOrderPDF = async (orderId) => {
  try {
    const response = await api.get(`/pdf/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('PDF Generation Error:', error.response || error);
    throw error.response?.data || error.message;
  }
};

export const getDownloadUrl = (filename) => {
  // Get token from the user object
  let token = '';
  const userStr = localStorage.getItem('user');
  if (userStr) {
    try {
      const user = JSON.parse(userStr);
      if (user && user.token) {
        token = user.token;
      }
    } catch (error) {
      console.error('Error parsing user from localStorage:', error);
    }
  }
  return `${api.defaults.baseURL}/pdf/download/${filename}?token=${token}`;
};