import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, AlertCircle, ChevronRight, X } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import { getCurrentUser } from '../services/authService';

// Create a custom event for profile updates
export const refreshProfileCompletion = () => {
  window.dispatchEvent(new CustomEvent('profileUpdated'));
};

const ProfileCompletionCard = () => {
  const { theme } = useTheme();
  const [user, setUser] = useState(null);
  const [dismissed, setDismissed] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [missingFields, setMissingFields] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  
  const calculateCompletion = useCallback((userData) => {
    const requiredFields = [
      { name: 'address', label: 'Adres' },
      { name: 'postalCode', label: 'Postcode' },
      { name: 'city', label: 'Plaats' },
      { name: 'kvkNumber', label: 'KVK nummer' },
      { name: 'btwNumber', label: 'BTW nummer' },
      { name: 'bankAccount', label: 'IBAN' }
    ];
    
    const missing = [];
    let completedCount = 0;
    
    // Always count the essential fields as completed (name, email, company, password)
    completedCount += 4;
    
    requiredFields.forEach(field => {
      if (userData[field.name]) {
        completedCount++;
      } else {
        missing.push(field);
      }
    });
    
    // Calculate percentage (4 essential fields + 6 optional fields = 10 total)
    const percentage = Math.round((completedCount / 10) * 100);
    setCompletionPercentage(percentage);
    setMissingFields(missing);
  }, []);
  
  // Function to load user data and calculate completion
  const loadUserData = useCallback(() => {
    // Check local storage for dismissed state
    const isDismissed = localStorage.getItem('profileCompletionDismissed');
    if (isDismissed === 'true') {
      setDismissed(true);
    }
    
    // Get current user data
    const userData = getCurrentUser();
    setUser(userData);
    
    // Calculate completion percentage and missing fields
    if (userData) {
      calculateCompletion(userData);
    }
  }, [calculateCompletion]);
  
  // Effect for initial load and manual refresh
  useEffect(() => {
    loadUserData();
  }, [refreshCounter, loadUserData]);
  
  // Effect to listen for profile update events
  useEffect(() => {
    const handleProfileUpdate = () => {
      setRefreshCounter(prev => prev + 1);
    };
    
    window.addEventListener('profileUpdated', handleProfileUpdate);
    
    return () => {
      window.removeEventListener('profileUpdated', handleProfileUpdate);
    };
  }, []);
  
  const handleDismiss = () => {
    setDismissed(true);
    localStorage.setItem('profileCompletionDismissed', 'true');
  };
  
  // Don't show the card if it's been dismissed or the profile is complete
  if (dismissed || completionPercentage === 100 || !user) {
    return null;
  }
  
  return (
    <div 
      className="rounded-lg shadow-md mb-6 relative overflow-hidden transition-all duration-300 animate-slideDown"
      style={{ 
        backgroundColor: theme === 'dark' ? 'rgba(37, 99, 235, 0.1)' : 'rgba(219, 234, 254, 1)',
        borderLeft: '4px solid var(--color-accent)'
      }}
    >
      <div className="p-4">
        <button 
          onClick={handleDismiss}
          className="absolute top-2 right-2 rounded-full p-1 transition-colors duration-200"
          style={{ color: 'var(--color-text-secondary)' }}
          aria-label="Dismiss"
        >
          <X size={20} />
        </button>
        
        <div className="flex items-start">
          <div className="mr-4 mt-1">
            {completionPercentage < 70 ? (
              <AlertCircle size={24} className="text-amber-500" />
            ) : (
              <CheckCircle size={24} className="text-green-500" />
            )}
          </div>
          
          <div className="flex-1">
            <h3 className="font-bold text-lg mb-1" style={{ color: 'var(--color-text-primary)' }}>
              Maak uw profiel compleet
            </h3>
            
            <p className="mb-3" style={{ color: 'var(--color-text-secondary)' }}>
              {completionPercentage < 70 
                ? 'Vul uw profiel aan om gebruik te maken van alle functies' 
                : 'U bent goed op weg! Maak uw profiel af voor optimaal gebruik'}
            </p>
            
            <div className="mb-3">
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium" style={{ color: 'var(--color-text-secondary)' }}>
                  Voortgang
                </span>
                <span className="text-sm font-medium" style={{ color: 'var(--color-text-secondary)' }}>
                  {completionPercentage}%
                </span>
              </div>
              <div 
                className="w-full bg-gray-200 rounded-full h-2 overflow-hidden"
                style={{ backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' }}
              >
                <div 
                  className="h-full rounded-full transition-all duration-500 ease-in-out"
                  style={{ 
                    width: `${completionPercentage}%`,
                    backgroundColor: completionPercentage < 70 ? 'var(--color-warning)' : 'var(--color-success)'
                  }}
                ></div>
              </div>
            </div>
            
            {missingFields.length > 0 && (
              <div className="mb-3">
                <p className="text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>
                  Nog toe te voegen:
                </p>
                <ul className="text-sm space-y-1 ml-1">
                  {missingFields.slice(0, 3).map((field) => (
                    <li 
                      key={field.name} 
                      className="flex items-center"
                      style={{ color: 'var(--color-text-secondary)' }}
                    >
                      <span className="inline-block w-2 h-2 rounded-full mr-2" style={{ backgroundColor: 'var(--color-accent)' }}></span>
                      {field.label}
                    </li>
                  ))}
                  {missingFields.length > 3 && (
                    <li style={{ color: 'var(--color-text-secondary)' }} className="text-sm">
                      ...en {missingFields.length - 3} andere
                    </li>
                  )}
                </ul>
              </div>
            )}
            
            <Link
              to="/bedrijfsinstellingen"
              className="inline-flex items-center font-medium text-sm rounded-md py-2 px-3 transition-all duration-200"
              style={{ 
                backgroundColor: theme === 'dark' ? 'rgba(37, 99, 235, 0.2)' : 'rgba(37, 99, 235, 0.1)', 
                color: 'var(--color-accent)' 
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = theme === 'dark' ? 'rgba(37, 99, 235, 0.3)' : 'rgba(37, 99, 235, 0.2)'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = theme === 'dark' ? 'rgba(37, 99, 235, 0.2)' : 'rgba(37, 99, 235, 0.1)'}
            >
              Profiel voltooien
              <ChevronRight size={16} className="ml-1" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompletionCard; 