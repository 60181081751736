// src/components/CustomerForm.js
import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { createCustomer, updateCustomer } from '../services/customerService';

const CustomerForm = ({ customer, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    postalCode: '',
    city: '',
    kvkNumber: '',
    btwNumber: '',
    active: true
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  
  useEffect(() => {
    if (customer) {
      setFormData({
        name: customer.name || '',
        company: customer.company || '',
        email: customer.email || '',
        phone: customer.phone || '',
        address: customer.address || '',
        postalCode: customer.postalCode || '',
        city: customer.city || '',
        kvkNumber: customer.kvkNumber || '',
        btwNumber: customer.btwNumber || '',
        active: customer.active !== undefined ? customer.active : true
      });
    }
  }, [customer]);

  // Validate KVK number
  const validateKvkNumber = (value) => {
    if (!value) return ''; // Empty is valid
    if (!/^\d{8}$/.test(value.trim())) {
      return 'KVK-nummer moet uit 8 cijfers bestaan';
    }
    return '';
  };

  // Validate BTW number
  const validateBtwNumber = (value) => {
    if (!value) return ''; // Empty is valid
    if (!/^NL\d{9}B\d{2}$/.test(value.replace(/\s/g, ''))) {
      return 'BTW-nummer moet in formaat NL123456789B01 zijn';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Real-time validation for KVK and BTW numbers
    if (name === 'kvkNumber') {
      const error = validateKvkNumber(value);
      setValidationErrors(prev => ({
        ...prev,
        kvkNumber: error
      }));
    } else if (name === 'btwNumber') {
      const error = validateBtwNumber(value);
      setValidationErrors(prev => ({
        ...prev,
        btwNumber: error
      }));
    }
  };

  const validateForm = () => {
    const kvkError = validateKvkNumber(formData.kvkNumber);
    const btwError = validateBtwNumber(formData.btwNumber);
    
    const errors = {
      kvkNumber: kvkError,
      btwNumber: btwError
    };
    
    setValidationErrors(errors);
    return !kvkError && !btwError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!validateForm()) {
      return;
    }
    
    setError('');
    setIsLoading(true);
    
    try {
      if (customer && customer._id) {
        // Update existing customer
        await updateCustomer(customer._id, formData);
      } else {
        // Create new customer
        await createCustomer(formData);
      }
      
      onSuccess();
    } catch (err) {
      setError(err.message || 'Er is een fout opgetreden bij het opslaan van de klant.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 overflow-y-auto flex items-start justify-center p-4 sm:p-6 md:p-10 z-50" style={{ backgroundColor: 'var(--color-modal-overlay)' }}>
      <div className="w-full max-w-md max-h-[90vh] overflow-y-auto rounded-lg shadow-xl" style={{ backgroundColor: 'var(--color-bg-primary)' }}>
        <div className="sticky top-0 z-10 flex justify-between items-center p-4 border-b" style={{ backgroundColor: 'var(--color-bg-primary)', borderColor: 'var(--color-border)' }}>
          <h3 className="text-lg font-semibold" style={{ color: 'var(--color-text-primary)' }}>
            {customer ? 'Klant bewerken' : 'Nieuwe klant'}
          </h3>
          <button 
            onClick={onClose}
            className="p-2 rounded-full hover:bg-opacity-10"
            style={{ 
              color: 'var(--color-text-secondary)', 
              '&:hover': { backgroundColor: 'var(--color-bg-tertiary)' } 
            }}
            aria-label="Sluiten"
          >
            <X size={20} />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="p-4">
          {error && (
            <div className="mb-4 p-3 rounded-md" style={{ 
              backgroundColor: 'var(--color-error)', 
              color: '#ffffff',
              opacity: 0.8
            }}>
              {error}
            </div>
          )}
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Naam</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Bedrijf</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Telefoon</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Adres</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
            />
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Postcode</label>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                style={{ 
                  backgroundColor: 'var(--color-input-bg)', 
                  borderColor: 'var(--color-input-border)',
                  color: 'var(--color-text-primary)' 
                }}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Plaats</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                style={{ 
                  backgroundColor: 'var(--color-input-bg)', 
                  borderColor: 'var(--color-input-border)',
                  color: 'var(--color-text-primary)' 
                }}
              />
            </div>
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>KVK-nummer</label>
            <input
              type="text"
              name="kvkNumber"
              value={formData.kvkNumber || ''}
              onChange={handleChange}
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: validationErrors.kvkNumber ? 'var(--color-error)' : 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
              placeholder="12345678"
            />
            {validationErrors.kvkNumber && (
              <p className="mt-1 text-sm" style={{ color: 'var(--color-error)' }}>{validationErrors.kvkNumber}</p>
            )}
            <p className="mt-1 text-xs" style={{ color: 'var(--color-text-tertiary)' }}>Formaat: 8 cijfers, bijv. 12345678</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>BTW-nummer</label>
            <input
              type="text"
              name="btwNumber"
              value={formData.btwNumber || ''}
              onChange={handleChange}
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:border-transparent"
              style={{ 
                backgroundColor: 'var(--color-input-bg)', 
                borderColor: validationErrors.btwNumber ? 'var(--color-error)' : 'var(--color-input-border)',
                color: 'var(--color-text-primary)' 
              }}
              placeholder="NL123456789B01"
            />
            {validationErrors.btwNumber && (
              <p className="mt-1 text-sm" style={{ color: 'var(--color-error)' }}>{validationErrors.btwNumber}</p>
            )}
            <p className="mt-1 text-xs" style={{ color: 'var(--color-text-tertiary)' }}>Formaat: NL + 9 cijfers + B + 2 cijfers, bijv. NL123456789B01</p>
          </div>
          
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="active"
                checked={formData.active}
                onChange={handleChange}
                className="h-5 w-5"
                style={{ accentColor: 'var(--color-accent)' }}
              />
              <span className="ml-2" style={{ color: 'var(--color-text-primary)' }}>Actieve klant</span>
            </label>
          </div>
          
          <div className="sticky bottom-0 pt-4 border-t flex justify-end space-x-3" style={{ 
            backgroundColor: 'var(--color-bg-primary)', 
            borderColor: 'var(--color-border)' 
          }}>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded-md"
              style={{ 
                backgroundColor: 'transparent',
                borderColor: 'var(--color-border)',
                color: 'var(--color-text-primary)' 
              }}
            >
              Annuleren
            </button>
            <button
              type="submit"
              disabled={isLoading || validationErrors.kvkNumber || validationErrors.btwNumber}
              className="px-4 py-2 rounded-md text-white"
              style={{ 
                backgroundColor: (validationErrors.kvkNumber || validationErrors.btwNumber) 
                  ? 'var(--color-text-tertiary)' 
                  : 'var(--color-accent)',
                cursor: (validationErrors.kvkNumber || validationErrors.btwNumber) ? 'not-allowed' : 'pointer'
              }}
            >
              {isLoading ? 'Bezig met opslaan...' : customer ? 'Bijwerken' : 'Opslaan'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerForm;