// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, Edit3, Trash2, Send, Download, Filter, FileText, AlertTriangle } from 'lucide-react';
import { getOrders, deleteOrder, updateOrderStatus } from '../services/orderService';
import { getCustomers } from '../services/customerService';
import { generateOrderPDF, getDownloadUrl } from '../services/pdfService';
import DeleteModal from '../components/DeleteModal';
import ProfileCompletionCard from '../components/ProfileCompletionCard';
import { resendVerificationEmail, getCurrentUser, getUserProfile } from '../services/authService';

const Dashboard = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [stats, setStats] = useState({
    totalOrders: 0,
    monthlyRevenue: 0,
    activeCustomers: 0,
    orderGrowth: "0%",
    revenueGrowth: "0%",
    customerGrowth: "0%"
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isResendingVerification, setIsResendingVerification] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [currentUser, setCurrentUser] = useState(getCurrentUser());
  const [searchTerm, setSearchTerm] = useState('');
  const [timeFilter, setTimeFilter] = useState('month'); // 'month', 'quarter', 'year'

  useEffect(() => {
    fetchData();
    refreshUserStatus();
  }, []);

  // Apply filters whenever orders, searchTerm or timeFilter changes
  useEffect(() => {
    if (orders.length > 0) {
      applyFilters();
    }
  }, [orders, searchTerm, timeFilter]);

  // Function to refresh user's status including email verification
  const refreshUserStatus = async () => {
    try {
      // Get fresh user data from the server
      const userData = await getUserProfile();
      
      // Update localStorage with the fresh user data
      if (userData) {
        const storedUser = getCurrentUser();
        if (storedUser) {
          const updatedUser = { ...storedUser, ...userData };
          localStorage.setItem('user', JSON.stringify(updatedUser));
          setCurrentUser(updatedUser);
        }
      }
    } catch (err) {
      console.error('Error refreshing user status:', err);
    }
  };

  // Apply search and time filters to orders
  const applyFilters = () => {
    // Filter by search term
    let filtered = orders;
    
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(order => 
        order.orderNumber?.toLowerCase().includes(term) ||
        (typeof order.customer === 'object' ? order.customer?.name?.toLowerCase().includes(term) : order.customer?.toLowerCase().includes(term)) ||
        order.project?.toLowerCase().includes(term)
      );
    }
    
    // Filter by time period
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    
    if (timeFilter === 'month') {
      // Current month
      filtered = filtered.filter(order => {
        const orderDate = new Date(order.date);
        return orderDate.getMonth() === currentMonth && orderDate.getFullYear() === currentYear;
      });
    } else if (timeFilter === 'quarter') {
      // Current quarter
      const currentQuarter = Math.floor(currentMonth / 3);
      filtered = filtered.filter(order => {
        const orderDate = new Date(order.date);
        const orderQuarter = Math.floor(orderDate.getMonth() / 3);
        return orderQuarter === currentQuarter && orderDate.getFullYear() === currentYear;
      });
    } else if (timeFilter === 'year') {
      // Current year
      filtered = filtered.filter(order => {
        const orderDate = new Date(order.date);
        return orderDate.getFullYear() === currentYear;
      });
    }
    
    setFilteredOrders(filtered);
    
    // Calculate stats based on filtered orders
    calculateStats(filtered, customers, timeFilter);
  };

  // Calculate growth rates by comparing current period with previous period
  const calculateGrowth = (currentValue, previousValue) => {
    if (previousValue === 0) return currentValue > 0 ? "+100%" : "0%";
    const growthRate = ((currentValue - previousValue) / previousValue) * 100;
    return growthRate > 0 ? `+${growthRate.toFixed(0)}%` : `${growthRate.toFixed(0)}%`;
  };

  // Calculate statistics based on filtered orders
  const calculateStats = (filtered, customers, timeFilter) => {
    // Current period stats
    const totalOrders = filtered.length;
    const activeCustomers = customers.filter(customer => customer.active).length;
    const monthlyRevenue = filtered.reduce((total, order) => {
      const orderTotal = order.items.reduce((sum, item) => {
        return sum + (item.quantity * item.price * (1 + item.vat / 100));
      }, 0);
      return total + orderTotal;
    }, 0);

    // Previous period stats
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    
    let previousPeriodOrders = [];
    
    if (timeFilter === 'month') {
      // Previous month
      const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const prevMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
      previousPeriodOrders = orders.filter(order => {
        const orderDate = new Date(order.date);
        return orderDate.getMonth() === prevMonth && orderDate.getFullYear() === prevMonthYear;
      });
    } else if (timeFilter === 'quarter') {
      // Previous quarter
      const currentQuarter = Math.floor(currentMonth / 3);
      const prevQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
      const prevQuarterYear = currentQuarter === 0 ? currentYear - 1 : currentYear;
      
      previousPeriodOrders = orders.filter(order => {
        const orderDate = new Date(order.date);
        const orderQuarter = Math.floor(orderDate.getMonth() / 3);
        return orderQuarter === prevQuarter && orderDate.getFullYear() === prevQuarterYear;
      });
    } else if (timeFilter === 'year') {
      // Previous year
      previousPeriodOrders = orders.filter(order => {
        const orderDate = new Date(order.date);
        return orderDate.getFullYear() === currentYear - 1;
      });
    }
    
    const prevTotalOrders = previousPeriodOrders.length;
    
    const prevMonthlyRevenue = previousPeriodOrders.reduce((total, order) => {
      const orderTotal = order.items.reduce((sum, item) => {
        return sum + (item.quantity * item.price * (1 + item.vat / 100));
      }, 0);
      return total + orderTotal;
    }, 0);
    
    // Calculate growth rates
    const orderGrowth = calculateGrowth(totalOrders, prevTotalOrders);
    const revenueGrowth = calculateGrowth(monthlyRevenue, prevMonthlyRevenue);
    
    // Customer growth is more complex and would require historical data
    // Using a placeholder for now - in a real app, this would compare customer counts over time
    const customerGrowth = "+5%";
    
    setStats({
      totalOrders,
      monthlyRevenue,
      activeCustomers,
      orderGrowth,
      revenueGrowth,
      customerGrowth
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      
      // Fetch orders
      const ordersData = await getOrders();
      setOrders(ordersData);
      
      // Fetch customers
      const customersData = await getCustomers();
      setCustomers(customersData);
      
      // Initial filter setup (will be refined in useEffect)
      setFilteredOrders(ordersData);
      
      // Initial stats calculation
      const activeCustomers = customersData.filter(customer => customer.active).length;
      
      setStats(prevStats => ({
        ...prevStats,
        activeCustomers
      }));
      
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van de gegevens.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditOrder = (orderId) => {
    navigate(`/inkooporders/${orderId}/edit`);
  };

  const handleDeleteClick = (order) => {
    setSelectedOrder(order);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!selectedOrder) return;
    
    try {
      await deleteOrder(selectedOrder._id);
      const updatedOrders = orders.filter(o => o._id !== selectedOrder._id);
      setOrders(updatedOrders);
      setShowDeleteModal(false);
      setSelectedOrder(null);
      
      // Filters and stats will be updated via useEffect
    } catch (err) {
      setError('Er is een fout opgetreden bij het verwijderen van de order.');
      console.error(err);
    }
  };

  const handleGeneratePDF = async (order) => {
    try {
      setIsPdfLoading(true);
      const result = await generateOrderPDF(order._id);
      
      if (result.success) {
        // Open the PDF in a new window
        window.open(getDownloadUrl(result.filename), '_blank');
      }
    } catch (err) {
      console.error('PDF Generation Error:', err);
      setError('Er is een fout opgetreden bij het genereren van de PDF.');
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setIsResendingVerification(true);
      setVerificationMessage('');
      await resendVerificationEmail();
      setVerificationMessage('Verificatie e-mail is opnieuw verzonden');
      setTimeout(() => setVerificationMessage(''), 5000);
    } catch (error) {
      setVerificationMessage('Er is een fout opgetreden bij het verzenden van de verificatie e-mail');
    } finally {
      setIsResendingVerification(false);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nl-NL', options);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTimeFilterChange = (e) => {
    setTimeFilter(e.target.value);
  };
  
  return (
    <>
      {/* Header */}
      <header style={{ backgroundColor: 'var(--color-bg-primary)', borderBottom: '1px solid var(--color-border)' }} 
              className="shadow-sm p-4 flex flex-col sm:flex-row justify-between items-center gap-4">
        <h2 style={{ color: 'var(--color-text-primary)' }} className="text-2xl font-semibold">Dashboard</h2>
        <div className="flex flex-wrap gap-2">
          <Link 
            to="/nieuwe-order" 
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center text-white transition-all duration-200"
            style={{ 
              backgroundColor: 'var(--color-accent)',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onMouseOver={(e) => {
              // Use a darker background color for better contrast with white text
              e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
              e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
              e.currentTarget.style.transform = 'translateY(-1px)';
              // Make text brighter for better contrast
              e.currentTarget.style.color = '#ffffff';
              e.currentTarget.style.fontWeight = '600';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-accent)';
              e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.fontWeight = 'normal';
            }}
          >
            <Plus className="mr-2" size={18} /> Nieuwe Order
          </Link>
          <button 
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center transition-all duration-200"
            style={{ 
              backgroundColor: 'var(--color-bg-tertiary)', 
              color: 'var(--color-text-primary)',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-hover-btn)';
              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.transform = 'translateY(-1px)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--color-bg-tertiary)';
              e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            <Download size={18} className="mr-2" /> Exporteren
          </button>
        </div>
      </header>
      
      {/* Content Area */}
      <main style={{ backgroundColor: 'var(--color-bg-secondary)' }} className="flex-1 overflow-x-hidden overflow-y-auto p-4 sm:p-6">
        {error && (
          <div style={{ 
            backgroundColor: 'rgba(220, 38, 38, 0.1)',
            borderColor: 'var(--color-error)',
            color: 'var(--color-error)'
          }} className="border rounded mb-6 px-4 py-3">
            {error}
          </div>
        )}
        
        {currentUser && currentUser.isEmailVerified === false && (
          <div 
            className="mb-6 p-4 rounded-lg border flex items-start"
            style={{ 
              backgroundColor: 'rgba(234, 179, 8, 0.1)',
              borderColor: 'var(--color-warning)',
              color: 'var(--color-text-secondary)'
            }}
          >
            <AlertTriangle className="flex-shrink-0 mr-3 mt-0.5" style={{ color: 'var(--color-warning)' }} size={20} />
            <div>
              <p className="font-medium">Uw e-mailadres is nog niet geverifieerd</p>
              <p className="text-sm mt-1">Controleer uw inbox voor de verificatie e-mail of 
                <button 
                  onClick={handleResendVerification}
                  disabled={isResendingVerification}
                  className="ml-1 underline focus:outline-none hover:text-blue-600 disabled:opacity-50"
                >
                  {isResendingVerification ? 'Verzenden...' : 'klik hier om opnieuw te verzenden'}
                </button>
              </p>
              {verificationMessage && (
                <p className="text-sm mt-2 font-medium" style={{ color: 'var(--color-success)' }}>
                  {verificationMessage}
                </p>
              )}
            </div>
          </div>
        )}
        
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"></div>
          </div>
        ) : (
          <>
            {/* Profile Completion Card */}
            <ProfileCompletionCard />
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
              {/* Quick Stats Cards */}
              <StatCard 
                title="Totale Orders" 
                value={stats.totalOrders.toString()} 
                change={stats.orderGrowth} 
                positive={!stats.orderGrowth.startsWith('-')} 
              />
              <StatCard 
                title="Maandomzet" 
                value={`€ ${stats.monthlyRevenue.toFixed(2)}`} 
                change={stats.revenueGrowth} 
                positive={!stats.revenueGrowth.startsWith('-')} 
              />
              <StatCard 
                title="Actieve Klanten" 
                value={stats.activeCustomers.toString()} 
                change={stats.customerGrowth} 
                positive={!stats.customerGrowth.startsWith('-')} 
              />
            </div>
            
            {/* Orders Table */}
            <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg overflow-hidden">
              <div style={{ 
                backgroundColor: 'var(--color-bg-tertiary)',
                borderBottom: '1px solid var(--color-border)'
              }} className="p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">Recente Orders</h3>
                <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                  <input 
                    type="text" 
                    placeholder="Zoeken..." 
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border rounded-md px-3 py-2 w-full sm:w-64 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                  />
                  <select 
                    value={timeFilter}
                    onChange={handleTimeFilterChange}
                    className="border rounded-md px-3 py-2 w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                  >
                    <option value="month">Deze Maand</option>
                    <option value="quarter">Afgelopen Kwartaal</option>
                    <option value="year">Heel Jaar</option>
                  </select>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead style={{ backgroundColor: 'var(--color-bg-tertiary)', borderBottom: '1px solid var(--color-border)' }}>
                    <tr>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Order Nr</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Klant</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Project</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="hidden md:table-cell p-3 text-left text-xs font-medium uppercase tracking-wider">Datum</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Totaal</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="p-3 text-left text-xs font-medium uppercase tracking-wider">Acties</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.length > 0 ? (
                      filteredOrders.map((order) => {
                        // Calculate order total
                        const orderTotal = order.items.reduce((sum, item) => {
                          return sum + (item.quantity * item.price * (1 + item.vat / 100));
                        }, 0);
                        
                        return (
                          <tr key={order._id} 
                              style={{ borderBottom: '1px solid var(--color-border)' }} 
                              className="transition-hover"
                              onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--color-hover-light)'} 
                              onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                            <td style={{ color: 'var(--color-text-primary)' }} className="p-3">{order.orderNumber}</td>
                            <td style={{ color: 'var(--color-text-primary)' }} className="p-3">
                              {order.customer && typeof order.customer === 'object' 
                                ? order.customer.name 
                                : (typeof order.customer === 'string' ? order.customer : 'Onbekende klant')}
                            </td>
                            <td style={{ color: 'var(--color-text-primary)' }} className="p-3">{order.project}</td>
                            <td style={{ color: 'var(--color-text-primary)' }} className="hidden md:table-cell p-3">{formatDate(order.date)}</td>
                            <td style={{ color: 'var(--color-text-primary)' }} className="p-3 font-semibold">€ {orderTotal.toFixed(2)}</td>
                            <td className="p-3">
                              <div className="flex space-x-1 sm:space-x-2">
                                <button 
                                  className="p-1 rounded-md transition-all duration-200"
                                  style={{ color: 'var(--color-accent)' }}
                                  onClick={() => handleEditOrder(order._id)}
                                  onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = 'var(--color-hover-primary)';
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = '';
                                    e.currentTarget.style.transform = 'scale(1)';
                                  }}>
                                  <Edit3 size={16} className="sm:w-[18px] sm:h-[18px]" />
                                </button>
                                <button 
                                  className="p-1 rounded-md transition-all duration-200"
                                  style={{ color: 'var(--color-text-secondary)' }}
                                  onClick={() => handleGeneratePDF(order)}
                                  disabled={isPdfLoading}
                                  onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = 'var(--color-hover-light)';
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = '';
                                    e.currentTarget.style.transform = 'scale(1)';
                                  }}>
                                  <FileText size={16} className="sm:w-[18px] sm:h-[18px]" />
                                </button>
                                <button 
                                  className="p-1 rounded-md transition-all duration-200"
                                  style={{ color: 'var(--color-success)' }}
                                  onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = 'var(--color-hover-success)';
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = '';
                                    e.currentTarget.style.transform = 'scale(1)';
                                  }}>
                                  <Send size={16} className="sm:w-[18px] sm:h-[18px]" />
                                </button>
                                <button 
                                  className="p-1 rounded-md transition-all duration-200"
                                  style={{ color: 'var(--color-error)' }}
                                  onClick={() => handleDeleteClick(order)}
                                  onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = 'var(--color-hover-error)';
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = '';
                                    e.currentTarget.style.transform = 'scale(1)';
                                  }}>
                                  <Trash2 size={16} className="sm:w-[18px] sm:h-[18px]" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ color: 'var(--color-text-secondary)' }} className="p-4 text-center">
                          {orders.length > 0 ? 'Geen orders gevonden die aan de criteria voldoen.' : 'Geen orders gevonden. Maak uw eerste order aan.'}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </main>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        title="Order verwijderen"
        message={`Weet u zeker dat u order ${selectedOrder?.orderNumber} wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.`}
        itemName={selectedOrder?.orderNumber}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
      />
    </>
  );
};

const StatCard = ({ title, value, change, positive }) => (
  <div style={{ backgroundColor: 'var(--color-bg-primary)' }} 
       className="shadow rounded-lg p-4 transition-hover hover:shadow-md">
    <div className="flex justify-between items-center">
      <div>
        <p style={{ color: 'var(--color-text-secondary)' }} className="text-sm mb-1">{title}</p>
        <p style={{ color: 'var(--color-text-primary)' }} className="text-2xl font-bold">{value}</p>
      </div>
      <div style={{ 
        backgroundColor: positive ? 'rgba(16, 185, 129, 0.1)' : 'rgba(220, 38, 38, 0.1)',
        color: positive ? 'var(--color-success)' : 'var(--color-error)'
      }} className="flex items-center p-1 rounded">
        <span className="text-xs font-medium">{change}</span>
      </div>
    </div>
  </div>
);

export default Dashboard;